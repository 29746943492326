import React from "react";

export default function TextBlock(props) {
  let textStyle;
  if (props.textStyle) {
    textStyle = props.textStyle;
  } else {
    textStyle = 'normal';
  }

  let textSize;
  if (props.textSize) {
    textSize = props.textSize;
  } else {
    textSize = 'large';
  }
  let fontClass;
  if (textSize === 'large') {
    fontClass = 'text-body-2';
  } else if (textSize === 'small') {
    fontClass = 'text-body-1';
  }

  let dropCapPaddingTop;
  let dropCapPaddingRight;
  if (textSize === 'large') {
    dropCapPaddingTop = '11px';
    dropCapPaddingRight = '7px';
  } else if (textSize === 'small') {
    dropCapPaddingTop = '3px';
    dropCapPaddingRight = '7px';
  }
  let dropCapStyles = {
    paddingTop: dropCapPaddingTop,
    paddingRight: dropCapPaddingRight
  }

  let firstCharacterLetter = true;
  if (Array.isArray(props.text)) {
    if (!props.text[0].substring(0, 1).match(/[a-zA-Z]/i)) {
      firstCharacterLetter = false;
    }
    if (props.text[0].length < 20) {
      dropCapStyles['marginTop'] = '-14px'
    }
  } else if (typeof props.text === 'string') {
    if (!props.text.substring(0, 1).match(/[a-zA-Z]/i)) {
      firstCharacterLetter = false;
    }
    if (props.text.length < 20) {
      dropCapStyles['marginTop'] = '-14px'
    }
  }


  return (
    <div>
      {Array.isArray(props.text) &&
        <>
          {props.text.map((paragraph, index) => (
            <div key={index}>
              {index === 0 &&
                <div>
                  {!firstCharacterLetter &&
                    <>
                      <p className={`exclude-markdown-styling float-left ${textStyle} ${fontClass}`}>"</p>
                      <span className="text-dropcaps float-left text-mint" style={dropCapStyles}>{paragraph.substring(1, 2)}</span>
                      <p className={`exclude-markdown-styling ${textStyle} ${fontClass}`} dangerouslySetInnerHTML={{ __html: paragraph.substring(2)}}></p>
                    </>
                  }
                  {firstCharacterLetter &&
                    <>
                      <span className="text-dropcaps float-left text-mint" style={dropCapStyles}>{paragraph.substring(0, 1)}</span>
                      <p className={`exclude-markdown-styling ${textStyle} ${fontClass}`} dangerouslySetInnerHTML={{ __html: paragraph.substring(1)}}></p>
                    </>
                  }
                </div>
              }
              {index > 0 &&
                <p className={`exclude-markdown-styling ${textStyle} ${fontClass} mt-10`} dangerouslySetInnerHTML={{ __html: paragraph}}></p>
              }
            </div>
          ))}
        </>
      }
      {typeof props.text === 'string' &&
        <>
          <div>
            {!firstCharacterLetter &&
              <>
                <p className={`exclude-markdown-styling float-left ${textStyle} ${fontClass}`}>"</p>
                <span className="text-dropcaps float-left text-mint" style={dropCapStyles}>{props.text.substring(1, 2)}</span>
                <p className={`exclude-markdown-styling ${textStyle} ${fontClass}`} dangerouslySetInnerHTML={{ __html: props.text.substring(2)}}></p>
              </>
            }
            {firstCharacterLetter &&
              <>
                <span className="text-dropcaps float-left text-mint" style={dropCapStyles}>{props.text.substring(0, 1)}</span>
                <p className={`exclude-markdown-styling ${textStyle} ${fontClass}`} dangerouslySetInnerHTML={{ __html: props.text.substring(1)}}></p>
              </>
            }
          </div>
        </>
      }

    </div>
  )
}