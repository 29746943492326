import React from "react"

export default function ArticleImage(props) {
  let altText = props.alt;
  if (!altText || (typeof altText === 'string' && altText.length === 0)) {
    altText = props.caption;
    if (!altText || (typeof altText === 'string' && altText.length === 0)) {
      let altTextChunks = props.src.split(/[/|.]+/).slice(-2)[0].split(/[-|_]+/);
      let nextAltText = '';
      for (let index in altTextChunks) {
        if (index === 0 && /\d/.test(altTextChunks[index])) {
          continue;
        }
        if (index === 0) {
          nextAltText += altTextChunks[index];
        } else {
          nextAltText += ' ' + altTextChunks[index];
        }
      }
      altText = nextAltText;
    }
  }

  let type = 'image';
  if (props.type) {
    type = props.type;
  }

  let classString;
  if (!props.classString) {
    classString = '';
  } else {
    classString = props.classString
  }

  let imageMaxWidth;
  if (props.imageMaxWidth) {
    imageMaxWidth = props.imageMaxWidth;
  } else {
    imageMaxWidth = '100%'
  }

  let imageMaxHeight;
  if (props.imageMaxHeight) {
    imageMaxHeight = props.imageMaxHeight;
  } else {
    imageMaxHeight = '100%'
  }

  let captionMaxWidth;
  if (props.captionMaxWidth) {
    captionMaxWidth = props.captionMaxWidth;
  } else {
    captionMaxWidth = '100%'
  }

  // Aspect Ratio: width/height => apple photo is 1.333 or 0.75

  return (
    <figure className={`${classString}`}>
      {type === 'image' && (
        <>
          {props.aspectRatio && (
            <img className="mx-auto" src={props.src} style={{aspectRatio: props.aspectRatio, imageMaxWidth: imageMaxWidth, imageMaxHeight: imageMaxHeight}} alt={altText}/>
          )}
          {!props.aspectRatio && (
            <img className="mx-auto" src={props.src} alt={altText} style={{maxWidth: imageMaxWidth, maxHeight: imageMaxHeight}}/>
          )}
        </>
      )}
      {type === 'video' && (
        <video className="mx-auto" alt={altText} style={{maxWidth: imageMaxWidth, maxHeight: imageMaxHeight}} controls autoPlay muted loop>
          <source src={props.src} type="video/mp4"></source>
        </video>
      )}
      {props.captionTitle && (
        <figcaption className="text-subheader-3 text-center mt-4 mx-auto" style={{maxWidth: captionMaxWidth, marginTop: '12px', marginBottom: '-5px'}} dangerouslySetInnerHTML={{ __html: props.captionTitle}}></figcaption>
      )}
      {props.caption && (
        <figcaption className="text-body-1 italic text-center mt-4 mx-auto" style={{maxWidth: captionMaxWidth}} dangerouslySetInnerHTML={{ __html: props.caption}}></figcaption>
      )}
    </figure>
  )
}


