import React from "react";

export default function NewsletterTitle(props) {

  return (
    <div className="exclude-markdown-styling mb-12">
      <h6 className="text-subheader-1 text-mint mb-4 exclude-markdown-styling">{props.subtitle}</h6>
      <h5 className="text-header-2 text-mint mb-2 exclude-markdown-styling">{props.title}</h5>
      <p className="text-body-1 italic text-green exclude-markdown-styling" style={{'opacity': '0.4'}}>{props.summary}</p>
    </div>
  )
}